var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fun_template_my_page" },
    [
      _vm.is_custom
        ? [_c("custom")]
        : [
            _vm.$route.params.type == "purchase" ||
            _vm.$route.params.type == "sell" ||
            _vm.$route.params.type === "created"
              ? _c("div", { staticClass: "page_header_group" }, [
                  !_vm.package_details
                    ? _c(
                        "div",
                        {
                          staticClass: "tag_group cp",
                          class: { on: _vm.tag_type == "template" },
                          on: {
                            click: function ($event) {
                              _vm.tag_type = "template"
                            },
                          },
                        },
                        [_c("div", { staticClass: "tag" }, [_vm._v("任务")])]
                      )
                    : _vm._e(),
                  !_vm.package_details && _vm.$route.params.type !== "created"
                    ? _c(
                        "div",
                        {
                          staticClass: "tag_group cp",
                          class: { on: _vm.tag_type == "package" },
                          on: {
                            click: function ($event) {
                              _vm.tag_type = "package"
                              _vm.getPackageList()
                              _vm.search = ""
                            },
                          },
                        },
                        [_c("div", { staticClass: "tag" }, [_vm._v("教学包")])]
                      )
                    : _vm._e(),
                  _vm.$route.params.type === "created"
                    ? _c(
                        "div",
                        {
                          staticClass: "tag_group cp",
                          class: { on: _vm.tag_type === "course" },
                          on: {
                            click: function ($event) {
                              _vm.tag_type = "course"
                              _vm.search = ""
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "tag" }, [
                            _vm._v("\n          课程\n        "),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.package_details
                    ? _c(
                        "div",
                        {
                          staticClass: "return",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.package_details = false
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "icon cp" }),
                          _c("div", { staticClass: "text cp" }, [
                            _vm._v("返回"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "page_content_group" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.tag_type == "template" && !_vm.package_details,
                        expression: "tag_type=='template' && !package_details",
                      },
                    ],
                    staticClass: "content_group template_content_group",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tag_wrapper" },
                      [
                        _c(
                          "div",
                          { staticClass: "search_group d-flex align-center" },
                          [
                            _c("div", { staticClass: "search_box" }, [
                              _c("div", { staticClass: "icon" }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.template_search,
                                    expression: "template_search",
                                  },
                                ],
                                staticClass: "search",
                                attrs: {
                                  type: "text",
                                  placeholder: "输入内容，Enter进行搜索",
                                },
                                domProps: { value: _vm.template_search },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.doSearchTemplate.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.template_search = $event.target.value
                                  },
                                },
                              }),
                            ]),
                            _vm.router_type === "sell"
                              ? _c("putaway-filter-bar", {
                                  ref: "task_putaway_filter",
                                  staticClass: "putaway_filter_bar",
                                  attrs: {
                                    show_flag: String(_vm.show_flag),
                                    is_all: _vm.is_all,
                                    all_num: _vm.task_all_num,
                                    putaway_num: _vm.task_putaway_num,
                                    sold_out_num: _vm.task_sold_out_num,
                                  },
                                  on: {
                                    handleChange: _vm.handleChangePutawayFilter,
                                    handleOpenBantch: _vm.handleOpenBantch,
                                    handleCloseBantch: _vm.handleCloseBantch,
                                    handleSelectAll: _vm.handleSelectAllTask,
                                    handlePutawayByBantch:
                                      _vm.handlePutawayTaskByBantch,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.tag_list && _vm.tag_list.length
                          ? _c(
                              "div",
                              {
                                staticClass: "all_group cp",
                                class: { on: _vm.is_all_select },
                                on: { click: _vm.selectAllHandler },
                              },
                              [
                                _c("div", { staticClass: "icon" }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("全部标签"),
                                ]),
                              ]
                            )
                          : _c("div", { staticClass: "all_group cp" }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v("暂无标签"),
                              ]),
                            ]),
                        _c(
                          "div",
                          {
                            staticClass: "tag_group",
                            class: { more: _vm.more_tag },
                          },
                          _vm._l(_vm.tag_list, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "tag un_sel cp",
                                class: { selected: item.select },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectTag(item, index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.tagName))]
                            )
                          }),
                          0
                        ),
                        _c("transition", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.more_tag && 0,
                                  expression: "!more_tag && 0",
                                },
                              ],
                              staticClass: "more_group",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text",
                                  on: {
                                    click: function ($event) {
                                      _vm.more_tag = true
                                    },
                                  },
                                },
                                [_vm._v("展开更多")]
                              ),
                              _c("div", {
                                staticClass: "icon",
                                on: {
                                  click: function ($event) {
                                    _vm.more_tag = true
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "content_wrapper" }, [
                      _c(
                        "div",
                        {
                          staticClass: "template_wrapper",
                          attrs: { id: "template_wrapper" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "template_group" },
                            _vm._l(
                              _vm.template_my_list,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    ref: "unit",
                                    refInFor: true,
                                    staticClass: "template_unit",
                                  },
                                  [
                                    _c("thumb-task", {
                                      staticClass: "list_unit cp un_sel",
                                      attrs: {
                                        item: item,
                                        menu: true,
                                        show_time: true,
                                        sell: _vm.teaching,
                                        show_tag: !_vm.teaching,
                                        show_from: !_vm.teaching,
                                        show_teaching: _vm.teaching,
                                        show_audit: _vm.teaching,
                                        show_putaway: _vm.teaching,
                                        open_select: _vm.switch_select_task,
                                        canSetStatus: true,
                                      },
                                      on: {
                                        handleClick: function ($event) {
                                          return _vm.openTaskBox(
                                            item,
                                            !_vm.teaching
                                          )
                                        },
                                        handleAddTag: _vm.openTagBox,
                                        handleShare: _vm.shareTask,
                                        handlePutaway: _vm.handlePutaway,
                                        handleSoldOut: _vm.handleSoldOut,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                          _vm.load_more_template
                            ? _c(
                                "div",
                                {
                                  ref: "no_more",
                                  staticClass: "more_group cp",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.loadMoreTemplate.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v("展开更多"),
                                  ]),
                                  _c("div", { staticClass: "icon" }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.tag_type == "package" && !_vm.package_details,
                        expression: "tag_type=='package' && !package_details",
                      },
                    ],
                    staticClass: "content_group package_content_group",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "search_group justify-start d-flex align-center",
                      },
                      [
                        _vm.router_type != "purchase"
                          ? _c(
                              "div",
                              {
                                staticClass: "new_package cp mr-10",
                                on: {
                                  click: function ($event) {
                                    return _vm.openEditPackage()
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "icon" }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("新建教学包"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "search_box" }, [
                          _c("div", { staticClass: "icon" }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.search,
                                expression: "search",
                              },
                            ],
                            staticClass: "search",
                            attrs: {
                              type: "text",
                              placeholder: "输入内容，Enter进行搜索",
                            },
                            domProps: { value: _vm.search },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                _vm.getPackageList({ keyword: _vm.search })
                                _vm.switch_search = true
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.search = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm.router_type === "sell"
                          ? _c("putaway-filter-bar", {
                              ref: "package_putaway_filter",
                              staticClass: "package_putaway_filter_bar",
                              attrs: {
                                show_flag: String(_vm.package_show_flag),
                                is_all: _vm.is_package_all,
                                all_num: _vm.package_all_num,
                                putaway_num: _vm.package_putaway_num,
                                sold_out_num: _vm.package_sold_out_num,
                              },
                              on: {
                                handleChange:
                                  _vm.handleChangePackagePutawayFilter,
                                handleOpenBantch: _vm.handlePackageOpenBantch,
                                handleCloseBantch: _vm.handlePackageCloseBantch,
                                handleSelectAll: _vm.handlePackageSelectAll,
                                handlePutawayByBantch:
                                  _vm.handlePutawayPackageByBantch,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "package_wrapper" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.switch_search,
                              expression: "!switch_search",
                            },
                          ],
                          staticClass: "package_group",
                        },
                        [
                          _vm._l(_vm.package_list, function (item, index) {
                            return [
                              _c("package-thumbnail", {
                                key: index,
                                staticClass: "mr-20 mb-20",
                                attrs: {
                                  menu: true,
                                  item: item,
                                  sell: _vm.sell_package,
                                  open_select: _vm.switch_select_package,
                                },
                                on: {
                                  handleClick: _vm.openEditPackage,
                                  handleShare: _vm.touchShareBtn,
                                  handleDelete: _vm.deletePackage,
                                  handlePutaway: _vm.handlePutawayPackage,
                                  handleSoldOut: _vm.handleSoldOutPackage,
                                },
                              }),
                            ]
                          }),
                          _vm.loadmorePackage
                            ? _c("div", { staticClass: "loadmore_group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "loadmore cp",
                                    on: { click: _vm.doLoadmorePackage },
                                  },
                                  [_vm._v("加载更多")]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.switch_search,
                              expression: "switch_search",
                            },
                          ],
                          staticClass: "package_group",
                        },
                        [
                          _vm._l(
                            _vm.package_search_list,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "package_item cp",
                                  class: { sell: _vm.sell_package },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openEditPackage(item)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.title || " ")),
                                  ]),
                                  _c("img", {
                                    staticClass: "pic",
                                    attrs: {
                                      src: `${_vm.$img_url}${item.webpic}`,
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "price_group" }, [
                                    _c("div", { staticClass: "price" }, [
                                      _vm._v(
                                        "购买价: " +
                                          _vm._s(item.price / 100) +
                                          "元"
                                      ),
                                    ]),
                                    _vm.sell_package
                                      ? _c("div", { staticClass: "status" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("checkstatus")(
                                                item.checkstatus
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn cp",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deletePackage(item)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm.loadmorePackage
                            ? _c("div", { staticClass: "loadmore_group" }, [
                                _vm.sell_package
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "loadmore cp",
                                        on: { click: _vm.doLoadmorePackage },
                                      },
                                      [_vm._v("加载更多")]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.package_details,
                        expression: "package_details",
                      },
                    ],
                    staticClass: "content_group package_details",
                  },
                  [
                    _vm.package_intro
                      ? _c("div", { staticClass: "intro_group" }, [
                          _c("div", { staticClass: "buy_tag" }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.package_intro.title)),
                          ]),
                          _c("div", { staticClass: "intro_title" }, [
                            _vm._v("简介:"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "intro_content",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.package_intro.content || "暂无"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n                                点击购买课程，可同时获得两次专家在线答疑机会。\n--------------------\n《物料管理》是会E人独立研发“会、展、活动”行业的核心实践知识模块之一，属于执行类课程\n本课程主要讲解的是会务物料的重要性、定义、管理流程、制作过程、时间规划、需求满足以及仓储运输、发放回收等知识点讲解\n--------------------\n课程定位：行业初级，项目基础认知类\n适用人群：会展相关专业在校生、初入行业的工作者\n                            "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "template_list_group" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("教学模版列表："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "list_group" },
                        [
                          _vm._l(
                            _vm.package_template_list,
                            function (item, index) {
                              return [
                                _c("thumb-task", {
                                  key: index,
                                  staticClass: "list_unit cp un_sel",
                                  attrs: {
                                    item: item,
                                    menu: false,
                                    show_time: false,
                                    show_tag: false,
                                    separate: false,
                                  },
                                  on: {
                                    handleClick: function ($event) {
                                      return _vm.openTaskBox(item)
                                    },
                                  },
                                }),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _vm.tag_type === "course" ? _c("CoursePackageList") : _vm._e(),
              ],
              1
            ),
          ],
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_template_add_tags
          ? _c(
              "div",
              { staticClass: "template_add_tags" },
              [
                _c("template-add-tags", {
                  ref: "addTagBox",
                  on: {
                    closeTagBox: function ($event) {
                      _vm.switch_template_add_tags = false
                    },
                    refresh: _vm.init,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }